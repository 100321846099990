import React from "react"
// import OGImage from "../components/og-image"

// const node = {
//   frontmatter: {
//     heroine: "Divya Sasidharan",
//     ogcolor: "fire",
//   },
//   fields: {
//     portraitPath: "content/divya-sasidharan/divya-sasidharan.png",
//     backgroundPath: "images/og-backgrounds/fire.png",
//   },
// }

// const OGPage = () => <OGImage heroine={node} />
const OGPage = () => <h1>Test</h1>

export default OGPage
